import moment from 'moment';
import find from 'lodash-es/find';
import isNil from 'lodash-es/isNil';
import { calendarDateFormat } from '../utils/calendar';
import saveAs from './save-as';
import CALENDAR_FORMATS from '../constants/calendar-formats';
import { SMIME_OPERATIONS } from '../constants/smime';
import { SMIMEDefaultSetting, SMIMELastOperation } from '../constants/mailbox-metadata';
import { LOCAL_FOLDER_IDS } from '@zimbra/electron-app';
import { USER_FOLDER_IDS } from '../constants';

const HOP = Object.prototype.hasOwnProperty;

/**
 * Find and return the element in `arr` that has a key with name `key` whose value is equal to `value` if `value`
 * is not a regex, or passes the regex test if `value` is a regex
 * @param {Array} arr
 * @param {String} key
 * @param {*} value A value to == match against (no type equality), or a regex to test
 * @return {*} The element in `arr` that has the key matching `value`, or undefined if not found
 */
export function pluck(arr, key, value) {
	const predicate =
		// eslint-disable-next-line eqeqeq
		value && value.test ? item => value.test(item[key]) : item => item[key] == value;
	return find(arr, predicate);
}

export function getId(obj) {
	if (!obj) return;
	if (typeof obj === 'object') obj = obj.id;
	if (typeof obj === 'number') obj = String(obj);
	return obj;
}

export function getIsTruncated(currentNode) {
	let i, currentChild, result;
	if (currentNode.body) {
		return currentNode.truncated;
	} else if (currentNode.mimeParts) {
		for (i = 0; i < currentNode.mimeParts.length; i++) {
			currentChild = currentNode.mimeParts[i];
			result = getIsTruncated(currentChild);
			if (result !== false) {
				return result;
			}
		}
	}
	return false;
}

/**
 * Given two Arrays, return true if they are a list of the same IDs. Order matters.
 * Note: Two empty Arrays returns true
 */
export function shallowEqualIds(a, b) {
	if (!a || !b || a.length !== b.length) {
		return false;
	}
	return a === b || a.map(getId).join(',') === b.map(getId).join(',');
}

export function notFlagged(messages, flag) {
	return messages && messages.filter(m => !hasFlag(m, flag));
}

export function last(arr) {
	return arr[arr.length - 1];
}

export function getEmail(address) {
	const m = address && address.match(/<\s*(.+?)\s*>\s*$/);
	return m ? m[1] : address;
}

export function getFromEmail(message) {
	return find(message?.emailAddresses, ['type', 'f']) || {};
}

const compositeEmailMatchRegExp =
	/(.*\s)*(([^A-Za-z0-9])*)([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/;

export function parseAddress(str) {
	str = str.trim();

	/**
	 * There are two types of email address (str) we receive here.
	 * 1) Composite Address with pattern: "Name <Email Address>" (When email address is selected from list)
	 * 2) Simple Address with pattern: "Email Address" (When email address is either typed by hand/copy pasted or not available in the list)
	 * So, in both cases, we need to parse email address such that we have values for address and name.
	 * In exceptional cases, we will just return address as it is.
	 */

	if (emailRegex.test(str)) {
		const compositeEmail = str.match(compositeEmailMatchRegExp);

		if (compositeEmail?.[1]) {
			const name = compositeEmail[1].trim().replace(/"|'/g, '');
			const address = compositeEmail[4];
			return { name, address };
		}

		const extractEmail = str.match(emailRegex)?.[0];
		const simpleAddrMatchRegExp = /(.+)@(.+)/;
		const parts = extractEmail.match(simpleAddrMatchRegExp);

		if (parts) {
			return { address: parts[0], name: parts[1] };
		}
	}

	return { address: str, name: str };
}

/**
 *
 * @param {String} str
 * @returns Array of email or compositeAddress
 * ex: str = "bob test1@testdomain.com alice test2@testdomain.com" then
 * return ["bob test1@testdomain.com", "alice test2@testdomain.com"]
 * ex: str = "test1domain.com" then
 * return ["test1doamin.com"]
 */
export function extractCompositeAddress(str) {
	str = str.trim();

	const compositeEmail = str.match(compositeEmailMatchRegExp);

	if (compositeEmail?.[1]) {
		//extracts all email from given string
		const emails = str.match(new RegExp(emailRegex, 'g'));

		const compositeAddress = [];

		// extracts all composite email
		emails.reduce((source, email) => {
			const emailIndex = source.indexOf(email);
			const emailLength = email.length;

			const compositeAddressStr = source.slice(0, emailIndex + emailLength);
			compositeAddress.push(compositeAddressStr);
			return source.substr(emailIndex + emailLength);
		}, str);

		return compositeAddress;
	}

	return [str];
}

/** Get the domain portion of an email address
 * @example getEmailDomain("foo@my.bar.com") === "my.bar.com"
 *
 * @param {string} address the email address
 * @returns {string} the domain portion of the email address.  Returns a falsey value if no domain is found
 */
export function getEmailDomain(address) {
	const m = address && address.match(/@([^@]+)$/);
	return m && m[1];
}

/** Determine if an email address is an exact match or has a domain that matches a (sub)domain of the list of trusted addresses/domains
 * @param {string} emailAddress the email address to check
 * @param {string[]} trustedList an array of email addresses and/or domains to check against
 * @returns {boolean} true if domain is a (sub)domain of one of the domains in inDomains, false otherwise
 *
 * @example isAddressTrusted("joe@foo.bar.com", ["joe@foo.bar.com"]) === true //exact email match
 * @example isAddressTrusted("joe@foo.bar.com", ["example.org", "bar.com"]) === true //subdomain match against array
 * @example isAddressTrusted("joe@bar.com", ["foo.bar.com"]) === false //no match
 */
export function isAddressTrusted(emailAddress, trustedList) {
	if (!(emailAddress && trustedList)) return false;
	emailAddress = emailAddress.toLowerCase();
	const domain = getEmailDomain(emailAddress) || '';
	return trustedList.some(t => {
		t = t.toLowerCase();
		const index = domain.indexOf(`.${t}`);
		return (
			t === emailAddress || t === domain || (index >= 0 && index === domain.length - t.length - 1)
		);
	});
}

export function serializeAddress(address, name) {
	return name ? `"${name.replace(/"/g, '\\"')}" <${address}>` : address;
}

export function filterDuplicates(arr) {
	const out = [];
	for (let i = 0; i < arr.length; i++) {
		if (arr.indexOf(arr[i]) === i) {
			out.push(arr[i]);
		}
	}
	return out;
}

/** weeks = reduce(toGroups(7), []) */
export const toGroups = size => (acc, item, index) => {
	const group = (index / size) | 0;
	if (group === acc.length) acc.push([item]);
	else acc[group].push(item);
	return acc;
};

export function deepClone(obj) {
	if (typeof obj !== 'object' || !obj) return obj;
	const out = Array.isArray(obj) ? [] : {};
	let i;
	for (i in obj)
		if (Object.prototype.hasOwnProperty.call(obj, i)) {
			out[i] = deepClone(obj[i]);
		}
	return out;
}

export function empty(obj) {
	return obj === undefined || obj === null;
}

// TODO: Migrate consumers of `callWith`
export { callWith } from '@zimbra/util/src/call-with';

export const FLAGS = {
	unread: 'u',
	flagged: 'f',
	replied: 'r',
	sentByMe: 's',
	forwarded: 'w',
	calendarInvite: 'v',
	draft: 'd',
	deleted: 'x',
	notificationSent: 'n',
	attachment: 'a',
	urgent: '!',
	lowPriority: '?',
	priority: '+'
};

export function hasFlag(message, flag) {
	const flags = message.flag || message.flags;
	return flags ? flags.indexOf(FLAGS[flag] || flag) > -1 : false;
}

export function removeFlag(flags, flag) {
	return (flags || '').replace(flag, '');
}

export function addFlag(flags, flag) {
	return removeFlag(flags, flag) + flag;
}

export function replaceFlag(flags, flagRemove, flagAdd) {
	return (flags || '').replace(flagRemove, flagAdd);
}

const emailRegex =
	/(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

// domain format for amavis
const domainRegex =
	/(^@(\.?[a-z\-0-9]+)+$)|(^@\.$)|(^[a-z\-0-9]+(\.[a-z\-0-9]+)*$)|(^@\[([0-9]{1,3}\.){3}[0-9]{1,3}\]$)|(^@\[IPv6:[a-z0-9:]+\]$)/i;

/**
 * Return true when a string contains any email addresses.
 */
export function containsValidEmail(str) {
	return emailRegex.test(str);
}

/**
 * Return true when the entire string is one valid email
 */
export function isValidEmail(email) {
	const match = email?.match(emailRegex);
	return match?.[0] === email;
}

// check amavis-specific domain format
export function isValidDomain(domain) {
	const match = domain?.match(domainRegex);
	return match?.[0] === domain;
}

export function replaceAttributes(object, originalAttr, newAttr) {
	const originalValue = object[originalAttr];
	delete object[originalAttr];
	object[newAttr] = originalValue;
	return object;
}

export function queryToString(queryObj) {
	let str = '',
		key;

	for (key in queryObj)
		if (HOP.call(queryObj, key)) {
			str += `${str ? '&' : ''}${encodeURIComponent(key)}=${encodeURIComponent(queryObj[key])}`;
		}

	return str;
}

export function parseQueryString(str) {
	const query = {},
		parts = str.split('&');

	for (let i = 0; i < parts.length; i++) {
		const [, key, value] = parts[i].match(/^([^=]+)(?:=(.*))?/);
		query[decodeURIComponent(key)] = decodeURIComponent(value);
	}
	return query;
}

export function removeNode(node) {
	if (!node) return;
	node.parentNode.removeChild(node);
}

/**
 * Returns `true` if any word in `keywords` is found in `target`.
 * @param {String} keywords            A word or space delimeted sentence for searching the `target`.
 * @param {String} target              The target to be search on.
 * @param {Boolean} [caseSensitive]    If true, the search will be case sensitive. Ignore case otherwise.
 * @return {Boolean}                   If any word from `keywords` is found in `target`, return true.
 */
export function hasCommonSubstr(keywords, target, caseSensitive) {
	let i;
	if (!keywords || !target) {
		return false;
	}
	if (!caseSensitive) {
		keywords = keywords.toLowerCase();
		target = target.toLowerCase();
	}

	if (target.indexOf(keywords) !== -1) {
		return true;
	}
	keywords = keywords.split(' ');
	for (i = 0; i < keywords.length; ++i) {
		if (target.indexOf(keywords[i]) !== -1) {
			return true;
		}
	}

	return false;
}

function decimalToHex(decimal) {
	let BGRString = decimal.toString(16); //this is in brg format instead of rgb
	if (BGRString.length < 6) {
		BGRString = `${Array(6 - BGRString.length + 1).join('0')}` + BGRString;
	}

	const colorParts = BGRString.match(/.{1,2}/g);
	return '#' + colorParts.reverse().join('');
}

// color => Decimal in IE, rgb()/rgba() in modern browsers
export function colorCodeToHex(color) {
	if (!isNaN(color)) return decimalToHex(color);

	const rgb = color.toString().match(/\d+/g);
	if (rgb && rgb.length === 4 && parseInt(rgb[3], 10) === 0) {
		return 'transparent';
	}

	return rgb && rgb.length > 2
		? '#' +
				('0' + parseInt(rgb[0], 10).toString(16)).slice(-2) +
				('0' + parseInt(rgb[1], 10).toString(16)).slice(-2) +
				('0' + parseInt(rgb[2], 10).toString(16)).slice(-2)
		: 'transparent';
}

export function hexToRgb(hex) {
	// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
	const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
	hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16)
		  }
		: null;
}

export function isValidPort(port) {
	return parseInt(port, 10) > 0 && parseInt(port, 10) <= 65535;
}

/**
 * Given a URI, resolve it to a URL against the current document.
 * @param {String} uri       A URI, e.g. '/news/article/1'
 * @param {Boolean} appendBasePath indicates whether the basepath should be appended with the url
 * @return {String}          A URL, e.g. 'http://localhost:8080/news/article/1'
 */
export function absoluteUrl(uri, appendBasePath = false) {
	const a = document.createElement('a');
	a.href = appendBasePath ? getBasePath() + uri : uri;
	return a.href;
}

/** Used to read the basehref to route on that path */
export function getBasePath() {
	return BASE_PATH.slice(0, -1);
}

export function isSameOrigin(uri) {
	return window.location.origin === new URL(absoluteUrl(uri, true)).origin;
}

export function parseURI(uri) {
	const parser = document.createElement('a');
	parser.href = uri;

	return {
		protocol: parser.protocol,
		hostname: parser.hostname,
		port: parser.port,
		pathname: parser.pathname,
		search: parser.search,
		hash: parser.hash,
		host: parser.host
	};
}

/**
 * Returns a clone of {@param obj} without the keys listed in {@param properties}
 * @param {Object} obj             the object to be transformed
 * @param {String[]} properties    the properties to be deleted from {@param obj}
 * @returns {Object}               the {@param obj} object without the properties listed in {@param properties}
 */
export function objectWithoutProperties({ ...obj }, properties) {
	if (!properties || !properties.length) {
		return obj;
	}

	let property;
	for (property of properties)
		if (Object.prototype.hasOwnProperty.call(obj, property)) {
			delete obj[property];
		}

	return obj;
}

/**
 * @desc returns A collection of time values formatted using {@param format} and distant an amount in seconds defined by {@param interval}
 * @param {number} interval
 * @param {String} format
 * @returns {String[]}
 */
export function timeRange(interval = 900, format = 'LT') {
	const start = moment('00:00:00', 'HH:mm:ss');
	const end = start.clone().add(1, 'days');
	const result = [];

	while (start.isBefore(end)) {
		result.push(start.format(format));
		start.add(interval, 'seconds');
	}

	return result;
}

/**
 * Converts from a time format to another
 * @param {String} sourceFormat
 * @param {String} targetFormat
 * @param {String} time
 */
export function switchTimeFormat(time, sourceFormat = 'h:mm A', targetFormat = 'HHmm') {
	return moment(time, sourceFormat).format(targetFormat);
}

export function circularIndex(n, len) {
	return (n + len) % len;
}

/**
 * Takes in an input of type 'file' and sends back a resolved Promise once the file has been read as Text.
 * @param {*} file
 * @param {String[]} supportedFormats
 */
export function getFileContent(file = {}, supportedFormats = ['ics']) {
	return new Promise((resolve, reject) => {
		if (supportedFormats.findIndex(format => format === file.name.match(/\.(\w+)$/)[1]) === -1) {
			reject(new Error('Unsupported File Format'));
		}
		const reader = new FileReader();
		reader.onload = (
			() => e =>
				resolve(e.target.result)
		)(file);
		reader.readAsText(file);
	});
}

/**
 * returns the number equivalent of the Day String supplied as parameter
 * @param {String} dayStr
 * @returns {number}
 */
export function getDayNumber(dayStr) {
	return moment().day(dayStr).day();
}

/**
 * Saves the downloaded calendar as a file given the format.
 * @param {*} result
 * @param {String} format
 */
export function saveCalendarAs(result, format = 'ics') {
	const blob = new Blob([].concat(result), {
		type: 'text/calendar'
	});
	const filename = `${result.match(/X-WR-CALNAME:(\w+)/)[1]}-${moment()
		.format('YYYY-MM-DD-hhmmss')
		.toString()}.${format}`;
	const url = window.URL.createObjectURL(blob);
	saveAs({
		url,
		filename
	});
}

export const getFolder = (folders, ident) =>
	folders.filter(
		// eslint-disable-next-line eqeqeq
		f => f.absFolderPath == ident || f.name == ident || f.id == ident
	)[0];

export const computeEmail = (name, host) => {
	if (!isNil(host.match(/(imap|pop)\.(\w+\.\w+)/))) {
		return `${name}@${host.match(/(imap|pop)\.(\w+\.\w+)/)[2]}`;
	}
	return '';
};

export function dirname(dir) {
	return dir && dir.replace(/\/(.*)\/.*$/, '/$1');
}

export function basename(dir) {
	return dir && dir.replace(/.*\/(.*)$/, '$1');
}

export function camelcase(str) {
	return str && str.toLowerCase().replace(/[_-](.)/g, (m, $1) => `${$1.toUpperCase()}`);
}

export function upperSnakecase(str) {
	return str && str.replace(/[A-Z]/g, m => `_${m}`).toUpperCase();
}

/**
 * Given a path, return the first parent directory in camelCase
 * @example assert(pathToSliceName('/a/b/camel-case/foo.js') === 'camelCase'))
 */
export function pathToSliceName(dir) {
	return camelcase(basename(dirname(dir)));
}

export function uriSegment(pathname, index = 0) {
	const segment = pathname && pathname.split('/')[index + 1];
	return segment !== '' ? segment : null;
}

/**
 * Extracts value out of an `input`'s event according to the `input`'s type
 * @param {*} e the input event
 */
export const getInputValue = e =>
	e.target ? (e.target.type === 'checkbox' ? e.target.checked : e.target.value) : e;

export function getDateKey(date) {
	return calendarDateFormat(date, CALENDAR_FORMATS);
}

function formatBytes(bytes, decimals) {
	bytes = typeof bytes === 'string' ? parseInt(bytes, 10) : bytes;
	if (bytes === 0) return '0 Bytes';
	const k = 1024,
		dm = decimals || 2,
		sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
		i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function formatBytesTranslation(bytes, decimals) {
	const value = formatBytes(bytes, decimals);
	const [size, format] = value.split(' ');

	return {
		id: `app.sizeFormat.${format}`,
		unit: format,
		value: size
	};
}

export function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Given some XML, select a node and return an attribute from that node
 * @param {String} xml        An xml string
 * @param {String} selector   A DOM selector
 * @param {String} attr       An attribute to be used by `Node.getAttribute(attr)`
 * @returns {String|Number}   returns the value of the given attribute on the selected Node
 */
export function getXmlAttribute(xml, selector, attr) {
	const node = new DOMParser().parseFromString(xml, 'application/xml').querySelector(selector);

	return node && node.getAttribute(attr);
}

export function ensureWithinRange(min, max, input) {
	return Math.min(max, Math.max(min, input));
}

// Given an array of words, add each suffix in suffixes to each word.
export function suffixArray(arr, suffixes) {
	return arr.reduce((acc, word) => [...acc, ...suffixes.map(suffix => `${word}${suffix}`)], []);
}

export function withoutStrings(arr) {
	return arr ? arr.filter(item => typeof item !== 'string') : [];
}

// Converts time to a correct syntax that can be understood by MomentJS
export function convertTo24Format(time12h) {
	const [time, modifier] = time12h.split(' ');
	let [hours, minutes] = time.split(':');
	if (hours === '12') {
		hours = '00';
	}
	if (modifier === 'PM' || modifier === 'pm' || modifier === 'Pm') {
		hours = parseInt(hours, 10) + 12;
	}
	return hours + ':' + minutes;
}

export function base64ToBlob(imageData) {
	return fetch(imageData)
		.then(res => res.blob())
		.then(res => res);
}

export function reduceAncestors(node, fn, initialValue) {
	let result = fn(initialValue, node);
	while ((node = node.parentElement)) {
		result = fn(result, node);
	}
	return result;
}

/**
 * Used to find first parent that contains a specific attribute
 */
export function getFirstParentWithAttribute(node, attr) {
	return reduceAncestors(node, (acc, parent) => acc || (parent && attr in parent && parent));
}

/**
 * Used to encode HTML reserved characters in a string into valid html entities.
 * eg: '<' would be encoded to '&lt;'.
 * Use this when you want to encode a string before rendering it as HTML.
 */
export function htmlEncode(string) {
	const el = document.createElement('div');
	el.innerText = el.textContent = string;
	return el.innerHTML;
}

/**
 * Used to encode unicode characters into HTML entinties.
 * eg: 'Ä' would be encoded to 'A&#776;'.
 * Use this when you want to encode a string containing unicode characters. eg: When encoding filenames.
 */
export function unicodeToHtmlEntities(rawStr) {
	return rawStr.replace(/[^\x00-\x7F]/gim, function (i) {// eslint-disable-line
		return `&#${i.charCodeAt(0)};`;
	});
}

/**
 * Used to update the value unread messgaes on the badge count for desktop app
 * default value is set to 0
 * the value is set to 0 by default to show no mails are unread (during logout)
 */

export function updateDesktopBadgeCount(count = 0) {
	if (typeof process.env.ELECTRON_ENV !== 'undefined') {
		const { rendererIpc } = require('@zimbra/electron-app');
		rendererIpc.setUnreadMailCount(count);
	}
}

/**
 * Used to return the updated array of attachments that are less then the maximum fileuploadLimit
 * @param {Array} files of attachments with its properties
 * @param {Int} maxSizeLimit max limit anove which file cant be uploaded
 * @return {Array} new array of attachments that have size less than the maxLimit
 */

export function handleFileSizeExceed(files, maxSizeLimit) {
	return files.filter(file => {
		if (file.size < maxSizeLimit) {
			return file;
		}
	});
}

export function isUploadedEml(attachment) {
	return isEML(attachment) && attachment.part;
}

/* Used to check given attachment is EML type */
export function isEML(attachment) {
	return attachment.contentType === 'message/rfc822';
}

/**
 * Used to return the updated array of attachments that doensn't have any EML
 * @param {Array} attachments of message
 * @returns {Array} new array of attachments that doesn't have EML
 */
export function filterOutEMLattachment(attachments) {
	return attachments.filter(a => !isEML(a));
}

/**
 * Used to get SMIME operation for new mail as per user's setting
 * @param {Object} mailboxMetadata of user account
 * @returns {String} smimeOperation setting.
 */
export function getSMIMEOperation(mailboxMetadata) {
	let smimeOperation;
	const smimeDefaultSetting = mailboxMetadata[SMIMEDefaultSetting] || SMIME_OPERATIONS.noSignOrEnc,
		smimeLastOperation = mailboxMetadata[SMIMELastOperation];

	if (smimeDefaultSetting === SMIME_OPERATIONS.rememberSettings) {
		smimeOperation = smimeLastOperation ? smimeLastOperation : SMIME_OPERATIONS.noSignOrEnc;
	} else {
		smimeOperation = smimeDefaultSetting;
	}
	return smimeOperation;
}

export function emailIsInJunk({ folderId, local }) {
	return folderId === (local ? LOCAL_FOLDER_IDS : USER_FOLDER_IDS).JUNK.toString();
}

/**
 * Used to redirect to Login page and it will append
 * RelayState param with current url pathname + search if url is not pointing to root app
 * i.e.
 * case 1: For url https://<server>/modern/calendar it redirects to https://<server>/?RelayState=%2Fmodern%2Fcalendar
 * case 2: For url https://<server>/modern/email/Sent/message/262 it redirects to https://<server>?RelayState=%2Fmodern%2Femail%2FSent%2Fmessage%2F262
 * case 3: For url https://<server> it redirect to https://<server>
 */
export function redirectToLoginURL(baseURL) {
	baseURL = baseURL || window.location.origin;
	const updatedURL = new URL(baseURL);
	const pathName = window.location.pathname;
	if (pathName !== '/') {
		updatedURL.searchParams.set('RelayState', pathName + window.location.search);
	}
	window.location.href = updatedURL.href;
}

/**
 * Used to convert string to unicode
 * @param {String} str
 * @returns {String} unicode data
 */
const unescapeUnicode = str => {
	return str.replace(/\\u([a-fA-F0-9]{4})/g, function (g, m1) {
		return String.fromCharCode(parseInt(m1, 16));
	});
};

/**
 * Used to extract proerty value from localize js script of zimlet
 * @param {String} text zimlet localize js script code
 * @param {String} key property name
 * @returns {String} property value
 */
export const getValueFromLocalizeJSCode = (text, key) => {
	const keyRegex = new RegExp(`a.${key}.*`, 'gm');
	const keyValues = text.match(keyRegex);
	const valueRegex = /"(.*?)"/;
	const keyValueStr = keyValues[keyValues.length - 1];
	return unescapeUnicode(valueRegex.exec(keyValueStr)[1]);
};

/**
 * Used to check given array are different or not
 * @param {Array} a
 * @param {Array} b
 * @returns {Boolean}
 */
export const isArrayDiff = (a, b) => {
	if (a.length !== b.length) return true;
	if (a.length !== 0 && a.length === b.length) {
		const sortA = a.sort();
		const sortB = b.sort();

		return sortB.some(item => sortA.indexOf(item) === -1);
	}
	return false;
};

/**
 * Used to compare same index content of two arrays
 * @param {Array} current
 * @param {Array} update
 * @returns {Boolean}
 */
export const compareArrayContent = (current, updated) => {
	return current.reduce((acc, value, index) => {
		if (Array.isArray(value)) {
			return acc || isArrayDiff(value, updated[index]);
		}
		return acc || value !== updated[index];
	}, false);
};
